<template>
  <el-container id="app-menu">
    <el-aside
      theme="light"
      class="layout-sider"
      width="130px"
      style="padding-top: 0px"
    >
      <div style="color: red; font-weight: bold; text-align: center; padding: 20px 0;">用户：{{ user.userId }}</div>
      <div :class="{'list-item': true, 'active': current == 'plan'}" @click="current = 'plan'">
        晒图配置
      </div>
      <div :class="{'list-item': true, 'active': current == 'statistics'}" @click="current = 'statistics'">
        每日报数
      </div>
      <div :class="{'list-item': true, 'active': current == 'person'}" @click="current = 'person'">
        个人设置
      </div>
      <div :class="{'list-item': true, 'active': current == 'account'}" @click="current = 'account'">
        虚拟托号
      </div>
      <div :class="{'list-item': true, 'active': current == 'password'}" @click="current = 'password'">
        修改密码
      </div>
      <div :class="{'list-item': true, 'active': current == 'dialog'}" @click="current = 'dialog'">
        弹窗模拟
      </div>
      <div :class="{'list-item': true}" @click="clearCache">
        清除缓存
      </div>
      <div :class="{'list-item': true}" @click="logout">
        退出登陆
      </div>
    </el-aside>
    <el-main class="main-wrap">
      <div v-if="current == 'plan'">
        <div>
          <el-button type="primary" @click="showDialogPostPlan(0)">新增晒图</el-button>
        </div>
        <el-table
          :data="postPlans"
          :row-style="({row, rowIndex}) => {
            if (row.plan_type == 2) {
              return {background: '#d2e1ee'};
            }
          }"
        >
          <el-table-column
            prop="plan_user_id"
            label="账号"
            width="180">
            <template slot-scope="scope">
              {{ scope.row.plan_user_id }} ({{ scope.row.nickname }})
            </template>
          </el-table-column>
          <el-table-column
            prop="open"
            label="启用"
            width="80">
            <template v-slot="scope">
              <el-switch
                v-model="scope.row.open"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="handleSwitchOpen(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            prop="plan_time"
            label="预计时间"
            width="220">
            <template v-slot="scope">
              每天 {{ scope.row.plan_time }}<br />
              下一次 {{ scope.row.next_plan_time }}
            </template>
          </el-table-column>
          <el-table-column
            prop="plan_type"
            label="类型"
            width="80">
            <template v-slot="scope">
              <span v-if="scope.row.plan_type == 1">文字</span>
              <span v-if="scope.row.plan_type == 2">图片</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="group_id"
            label="群组"
            width="180">
            <template v-slot="scope">
              <span v-if="scope.row.groupInfo">{{ scope.row.groupInfo.name }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="状态"
            width="160">
            <template v-slot="scope">
              <span v-if="scope.row.status == 1">待发送</span>
              <span v-if="scope.row.status == 2">成功</span>
              <span v-if="scope.row.status == 3">失败（{{ scope.row.error }}）</span><br>
              {{ scope.row.post_time }}
            </template>
          </el-table-column>
          <el-table-column
            prop="content"
            :show-overflow-tooltip="true"
            label="内容">
            <template v-slot="scope">
              <span v-if="scope.row.plan_type == 1">{{ scope.row.content }}</span>
              <span v-if="scope.row.plan_type == 2"><el-image lazy :src="thumb(scope.row.content)"
                :preview-src-list="[thumb(scope.row.content)]" style="width: 30px; height: 30px" /></span>
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
            label="操作"
            width="140"
          >
            <template v-slot="scope">
              <el-button-group>
                <el-button type="primary" size="mini" @click="showDialogPostPlan(scope.row.id)">修改</el-button>
                <el-button type="danger" size="mini" @click="deletePostPlan(scope.row.id)">删除</el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
        <br />
        <el-pagination
          align="right"
          background
          layout="prev, pager, next"
          :current-page.sync="postPlanForm.page"
          @current-change="handleCurrentChange"
          :total="postPlanForm.total"
          :page-size="postPlanForm.limit">
        </el-pagination>
      </div>
      <div v-if="current == 'person'" style="max-width: 800px">
        <div class="avatar-upload">
          <el-upload
            class="avatar-uploader"
            action="asdf"
            :auto-upload="true"
            :show-file-list="false"
            :before-upload="uploadAvatar">
            <el-image style="width: 150px; height: 150px; " :src="thumb(baseFormData.avatar)"></el-image>
          </el-upload>
          <br>
          <div style="color: red">{{ user.userId }}</div>
        </div>
        <div title="基本资料">
          <div class="example">
            <el-form ref="baseForm" label-width="120px" :modelValue="baseFormData">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="用户ID" required>
                    <el-input v-model="baseFormData.user_id" disabled placeholder="请输入用户ID" />
                  </el-form-item>
                  <el-form-item label="昵称" required>
                    <el-input v-model="baseFormData.nickname" placeholder="请输入昵称" />
                  </el-form-item>
                  <el-form-item label="性别">
                    <el-radio-group v-model="baseFormData.gender">
                      <el-radio :label="0">不公开</el-radio>
                      <el-radio :label="1">男</el-radio>
                      <el-radio :label="2">女</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="好友添加" required>
                    <el-radio-group v-model="baseFormData.add_friend_type" style="text-align: left">
                      <el-radio :label="2">需要验证</el-radio>
                      <el-radio :label="3">不可添加</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="用户名" required>
                    <el-input v-model="baseFormData.username" placeholder="请输入用户名" />
                  </el-form-item>
                  <el-form-item label="艺名" required>
                    <el-input v-model="baseFormData.remark_nickname" placeholder="请输入艺名" />
                  </el-form-item>
                  <el-form-item label="个性签名">
                    <el-input type="textarea" v-model="baseFormData.motto" placeholder="请输入个性签名" />
                  </el-form-item>
                  <el-form-item label="图片发送" required>
                    <el-radio-group v-model="baseFormData.send_file_action" style="text-align: left">
                      <el-radio :label="0">先预览再发送</el-radio>
                      <el-radio :label="1">直接发送</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div style="text-align: center">
              <el-button type="primary" @click="handlePerson">保存</el-button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="current == 'account'">
        <div>
          <el-button type="primary" @click="showDialogSubAccount(0)">新增账号</el-button>
        </div>
        <br/>
        <el-table :data="accounts">
          <el-table-column
            prop="avatar"
            label="头像"
            width="70">
            <template v-slot="scope">
              <el-avatar :size="30" :src="thumb(scope.row.thumb_avatar)"></el-avatar>
            </template>
          </el-table-column>
          <el-table-column
            prop="user_id"
            label="账号"
            width="180">
          </el-table-column>
          <el-table-column
            prop="nickname"
            label="昵称"
            width="180">
          </el-table-column>
          <el-table-column
            prop="virtual_type"
            label="类型"
            width="180">
            <template v-slot="scope">
              <span v-if="scope.row.virtual_type == 2">群杀托号</span>
              <span v-if="scope.row.virtual_type == 3">晒图账号</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
            label="操作">
            <template v-slot="scope">
              <el-button type="primary" size="mini" @click="showDialogSubAccount(scope.row.id)">修改</el-button>
              <el-button type="danger" size="mini" @click="deleteSubAccount(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="current == 'password'">
        <div style="max-width: 500px;">
          <el-form ref="passwordForm" :model="passwordForm" :rules="passwordRules">
            <el-form-item prop="old_password" label="旧密码">
              <el-input v-model="passwordForm.old_password" type="password" />
            </el-form-item>
            <el-form-item prop="new_password" label="新密码">
              <el-input v-model="passwordForm.new_password" type="password" />
            </el-form-item>
            <el-form-item prop="re_password" label="确认密码">
              <el-input v-model="passwordForm.re_password" type="password" />
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="handlePassword">确认修改</el-button>
        </div>
      </div>
      <div v-if="current == 'statistics'">
        <div>
          <el-button type="primary" @click="showDialogStatistics(0)">新增/修改报数</el-button>
        </div>
        <br/>
        <el-table :data="financeStatistics">
          <el-table-column
            prop="day"
            label="日期"
            width="100">
          </el-table-column>
          <el-table-column
            prop="channel_name"
            label="渠道"
            width="80">
          </el-table-column>
          <el-table-column
            prop="shang_hao"
            label="上号"
            width="80">
          </el-table-column>
          <el-table-column
            prop="jin_fen"
            label="进粉数"
            width="80">
          </el-table-column>
          <el-table-column
            prop="ren_wu"
            label="任务数"
            width="80">
          </el-table-column>
          <el-table-column
            prop="san_fang"
            label="三方数">
          </el-table-column>
          <el-table-column
            prop="fen_price"
            label="粉价">
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注">
          </el-table-column>
        </el-table>
        <br />
        <el-pagination
          align="right"
          background
          layout="prev, pager, next"
          :current-page.sync="financeStatisticsForm.page"
          @current-change="handleFinanceStatisticsCurrentChange"
          :total="financeStatisticsForm.total"
          :page-size="financeStatisticsForm.limit">
        </el-pagination>
      </div>
      <div v-if="current == 'dialog'">
        <el-form>
          <el-form-item label="标题">
            <el-input v-model="dialogForm.title"/>
          </el-form-item>
          <el-form-item label="内容">
            <el-input v-model="dialogForm.content"/>
          </el-form-item>
          <el-form-item label="按钮">
            <el-input v-model="dialogForm.confirmText"/>
          </el-form-item>
          <el-button type="primary" @click="showAlert">生成</el-button>
        </el-form>
      </div>
    </el-main>
    <dialog-sub-account ref="dialogSubAccount" @close="closeDialogSubAccount"></dialog-sub-account>
    <dialog-post-plan ref="dialogPostPlan" @close="fetchPostPlan"></dialog-post-plan>
    <dialog-statistics ref="dialogStatistics" @close="fetchStatistics"></dialog-statistics>
  </el-container>
</template>
<script>

import {
  mapState
} from 'vuex'
import { dateTimeFormat, thumb, blobToBase64, clearStorage } from '@/utils/utils'
import { requestHttp } from "@/api/main";

import DialogSubAccount from "@/views/setting/components/dialog-sub-account";
import DialogPostPlan from "@/views/setting/components/dialog-post-plan";
import DialogStatistics from "@/views/setting/components/dialog-statistics";
import {
  YeIMUniSDK,
  YeIMUniSDKDefines
} from '@/utils/wzJun1-YeIM-Uni-SDK/js_sdk/yeim-uni-sdk.min';
import { ipc } from '@/utils/ipcRenderer';
import { ipcApiRoute } from '@/api/main';


export default {
  name: 'ChatList',
  components: {
    DialogSubAccount, DialogPostPlan, DialogStatistics
  },
  data() {
    return {
      menu: {},
      current: 'plan',
      keys: [],
      isLoad: true,
      records: [],
      baseFormData: {
        avatar: '',
        user_id: '',
        username: '',
        nickname: '',
        remark_nickname: '',
        motto: '',
        gender: 0,
        add_friend_type: 2,
        send_file_action: 0
      },
      postPlanForm: {
        page: 1,
        total: 0,
        limit: 2000
      },
      financeStatisticsForm: {
        page: 1,
        total: 0,
        limit: 50
      },
      postPlans: [],
      accounts: [],
      financeStatistics: [],
      passwordForm: {
        old_password: '',
        new_password: '',
        re_password: ''
      },
      passwordRules: {
        old_password: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        new_password: [
          { required: true, message: '请输入新密码', trigger: 'blur' }
        ],
        re_password: [
          { required: true, message: '请再次输入密码', trigger: 'blur' }
        ]
      },
      dialogForm: {
        title: '決済システムの提示です:',
        content: '',
        confirmText: '確認します'
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.yeim.user,
      friendList: state => state.yeim.friendList,
      friendApplyUnread: state => state.yeim.friendApplyUnread,
    }),
    friendListFilter() {
      return this.$store.state.yeim.friendList
    }
  },
  mounted() {
    this.fetchPostPlan()
  },
  watch: {
    current(val) {
      if (val == 'person') {
        this.baseFormData.user_id = this.user.user_id;
        this.baseFormData.username = this.user.username;
        this.baseFormData.avatar = this.user.avatar;
        this.baseFormData.nickname = this.user.nickname;
        this.baseFormData.remark_nickname = this.user.remark_nickname;
        this.baseFormData.add_friend_type = this.user.add_friend_type;
        this.baseFormData.send_file_action = this.user.send_file_action;
        if (this.user.motto) this.baseFormData.motto = this.user.motto;
        if (this.user.gender) this.baseFormData.gender = this.user.gender;
      }
      if (val == 'plan') {
        this.fetchPostPlan()
      }
      if (val == 'account') {
        this.fetchAccount()
      }
      if (val == 'statistics') {
        this.fetchStatistics()
      }
    }
  },
  created() {
    if (this.$route.params && this.$route.params.active) {
      this.current = this.$route.params.active
      if (this.current == 'person' && (this.user.username == '' || this.user.remark_nickname == '')) {
        this.$message.error({ message: '请填写用户名及艺名', offset: 200 })
      }
    }
  },
  methods: {
    dateTimeFormat,
    thumb,
    uploadAvatar(file) {
      const that = this
      const isLt2M = file.size / 1024 / 1024 < 20;

      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20 MB!');
        return false
      }

      blobToBase64(file, function (res) {
        that.baseFormData.avatar = res
      });

      return false;
    },
    handlePerson() {

      if (!this.baseFormData.avatar || !this.baseFormData.nickname) {
        this.$message.error('头像和昵称必须输入')
        return;
      }

      const params = this.baseFormData

      YeIMUniSDK.getInstance().updateUserInfo({
        ...params,
        success: (result) => {
          //更新成功，刷新本地用户缓存
          this.$store.dispatch("yeim/flushUserInfo");
          this.$message.success('修改成功')
        },
        fail: (err) => {
          this.$message.error(err.message)
        }
      });
    },
    showDialogPostPlan(uId) {
      this.$refs.dialogPostPlan.toggle(uId)
    },
    showDialogStatistics() {
      this.$refs.dialogStatistics.toggle()
    },
    deletePostPlan(id) {
      const that = this
      this.$confirm('删除后不可恢复，确认吗？', '提示').then(function () {
        requestHttp('agent/delete_post_plan', {
          'id': id
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            setTimeout(function () {
              that.fetchPostPlan()
            }, 2000)
          } else {
            that.$message.error(res.message)
          }
        })
      })
    },
    fetchPostPlan() {
      const that = this
      requestHttp('agent/get_post_plans', this.postPlanForm).then((res) => {
        if (res.code != 200) {
          that.$message.error(res.message)
        } else {
          this.postPlans = res.data.data
          this.postPlanForm.page = res.data.current_page
          this.postPlanForm.total = res.data.total
          this.postPlanForm.limit = res.data.per_page
        }
      })
    },
    fetchStatistics() {
      const that = this
      requestHttp('agent/get_finance_agent_statistics', this.financeStatisticsForm).then((res) => {
        if (res.code != 200) {
          that.$message.error(res.message)
        } else {
          this.financeStatistics = res.data.data
          this.financeStatisticsForm.page = res.data.current_page
          this.financeStatisticsForm.total = res.data.total
          this.financeStatisticsForm.limit = res.data.per_page
        }
      })
    },
    handleCurrentChange(e) {
      console.log(e)
      this.fetchPostPlan()
    },
    handleFinanceStatisticsCurrentChange() {
      this.fetchStatistics()
    },
    showDialogSubAccount(uId) {
      this.$refs.dialogSubAccount.toggle(uId)
    },
    closeDialogSubAccount() {
      this.fetchAccount()
    },
    fetchAccount() {
      const that = this
      that.accounts = []
      requestHttp('agent/get_virtual_users', {}).then((res) => {
        if (res.code != 200) {
          that.$message.error(res.message)
        } else {
          that.accounts = res.data
          YeIMUniSDK.getInstance().emit2(YeIMUniSDKDefines.EVENT.SUB_ACCOUNT_LIST_CHANGED, res.data);
        }
      })
    },
    deleteSubAccount(uId) {
      const that = this
      this.$confirm('删除后不可恢复，确认吗？', '提示').then(function () {
        requestHttp('agent/delete_virtual_user', {
          'id': uId
        }).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.message)
            setTimeout(function () {
              that.fetchAccount()
            }, 2000)
          } else {
            that.$message.error(res.message)
          }
        })
      })
    },
    handlePassword() {
      const that = this
      if (this.passwordForm.old_password == '') {
        this.$message.error('请输入旧密码');
        return;
      }
      if (this.passwordForm.new_password == '') {
        this.$message.error('请输入新密码');
        return;
      }
      if (this.passwordForm.re_password == '') {
        this.$message.error('请两次输入密码');
        return;
      }
      if (this.passwordForm.new_password != this.passwordForm.re_password) {
        this.$message.error('两次输入的密码不一致');
        return;
      }

      requestHttp('agent/password', this.passwordForm).then((res) => {
        console.log(res)
        if (res.code == 200) {
          that.$message.success(res.message)
        } else {
          that.$message.error(res.message)
        }
      })
    },
    clearCache() {
      const that = this
      this.$confirm('确认清除缓存？', '提示').then(function () {
        for (let key in localStorage) {
          if (key != 'user' && key != 'loginInfo') {
            localStorage.removeItem(key)
          }
        }
        // 重新加载好友等数据
        that.$store.state.conversationList = []
        that.$store.state.friendList = []
        that.$store.state.friendApplyUnread = []
        that.$store.state.friendApplyList = []

        YeIMUniSDK.getInstance().reload();

        YeIMUniSDK.getInstance().getGroupList({
          success: (res) => {
            that.$store.state.groupList = res.data;
          },
          fail: (err) => {
          }
        });

      });
    },
    refresh() {
      window.location.reload()
    },
    handleSwitchOpen(item) {
      const that = this
      requestHttp('agent/switch_post_plan_status', {
        id: item.id,
        open: item.open
      }).then((res) => {
        if (res.code == 200) {
          that.$message.success(res.message)
        } else {
          that.$message.error(res.message)
        }
      })
    },
    logout() {
      const that = this
      this.$confirm('确定退出？', '提示').then(function () {
        clearStorage()
        YeIMUniSDK.getInstance().disConnect()
        that.$router.push({ name: 'UserLogin' })
      })
    },
    showAlert() {
      this.$alert(this.dialogForm.content, this.dialogForm.title, {
        confirmButtonText: this.dialogForm.confirmText,
        type: 'warning',
        center: true
      })
    }
  }
};
</script>
<style lang="less" scoped>
#app-menu {
  height: 100%;

  .layout-sider {
    border-top: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    background-color: #FAFAFA;
    overflow: auto;
    padding-top: 50px;
  }

  .scroll-box {
    flex: 1;
    height: 500px;
    overflow: hidden;
  }

  .list-item {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 12px 30px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    border-left: 3px solid transparent;
    cursor: pointer;
    text-align: left;

    &:hover {
      background: #EFF0F1;
    }

    &.active {
      background: #EFF0F1;
      border-left: 3px solid #1890FF;
    }
  }

  .main-wrap {
    padding: 20px;

    .avatar-upload {
      text-align: center;
      padding-bottom: 20px;
    }
  }
}

</style>
