<template>
  <el-dialog
    v-loading="loading"
    title="报数"
    :visible.sync="dialogVisible"
    center
    width="800px"
    :before-close="handleClose">

    <div>
      <el-date-picker v-model="day" value-format="yyyy-MM-dd" @change="handleChangeDay"></el-date-picker>
    </div>
    <br/>

    <el-table :data="list">
      <el-table-column
        prop="channel_name"
        label="渠道"
        width="150">
      </el-table-column>
      <el-table-column
        prop="shang_hao"
        label="上号">
        <template slot-scope="scope">
          <el-input v-model="scope.row.shang_hao"/>
        </template>
      </el-table-column>
      <el-table-column
        prop="jin_fen"
        label="进粉数">
        <template slot-scope="scope">
          <el-input v-model="scope.row.jin_fen"/>
        </template>
      </el-table-column>
      <el-table-column
        prop="ren_wu"
        label="任务数">
        <template slot-scope="scope">
          <el-input v-model="scope.row.ren_wu"/>
        </template>
      </el-table-column>
      <el-table-column
        prop="san_fang"
        label="三方数">
        <template slot-scope="scope">
          <el-input v-model="scope.row.san_fang"/>
        </template>
      </el-table-column>
      <el-table-column
        prop="fen_price"
        label="粉价">
        <template slot-scope="scope">
          <el-input v-model="scope.row.fen_price" disabled/>
        </template>
      </el-table-column>
      <el-table-column
        prop="remark"
        label="备注">
        <template slot-scope="scope">
          <el-input v-model="scope.row.remark"/>
        </template>
      </el-table-column>
    </el-table>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">复制并提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { requestHttp } from "@/api/main";

import { getCurrentDay } from '@/utils/utils'

export default {
  name: "DialogStatistics",
  data() {
    return {
      dialogVisible: false,
      userId: 0,
      loading: false,
      day: '',
      list: [],
      rules: {}
    }
  },
  methods: {
    getCurrentDay,
    toggle() {
      this.day = this.getCurrentDay()
      this.fetchDetail()
      this.dialogVisible = true
    },
    handleChangeDay(e) {
      console.log(e)
      this.day = e
      this.fetchDetail()
    },
    fetchDetail() {
      const that = this
      requestHttp('agent/get_finance_agent_statistics', {
        day: this.day,
        limit: 100
      }).then((res) => {
        that.list = res.data.data
        if (res.code != 200) {
          that.$message.error(res.message)
        }
      })
    },
    handleSubmit() {
      const that = this
      this.handleCopy()
      requestHttp('agent/save_finance_agent_statistics', {
        day: this.day,
        list: this.list
      }).then((res) => {
        console.log(res)
        if (res.code == 200) {
          that.$message.success(res.message)

          setTimeout(function () {
            that.handleClose()
          }, 2000)
        } else {
          that.$message.error(res.message)
        }
      })
    },
    handleClose() {
      this.dialogVisible = false
      this.$emit('close')
    },
    handleCopy() {
      let context = this.day + "\n"
      this.list.forEach((item, idx) => {
        if (idx === 0) {
          context += item.line_id + "\n\n"
        }
        if (item.shang_hao != '' || item.jin_fen != '' || item.ren_wu != '' || item.san_fang != '' || item.remark != '') {
          context += item.channel_name + "\n"
          if (item.shang_hao != '') {
            context += "上号：" + item.shang_hao + "\n"
          }
          if (item.jin_fen != '') {
          context += "进粉：" + item.jin_fen + "\n"
          }
          if (item.ren_wu != '') {
          context += "任务：" + item.ren_wu + "\n"
          }
          if (item.san_fang != '' || item.remark != '') {
            context += "三方：" + item.san_fang + (item.remark != '' ? ' +   (' + item.remark + ')' : '') + "\n\n"
          }
        }
      })
      // 创建输入框元素
      let oInput = document.createElement('textarea');
      // 将想要复制的值
      oInput.innerHTML = context;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand('Copy');
      // 弹出复制成功信息
      // this.$message.success('复制成功');
      // 复制后移除输入框
      oInput.remove();
    }
  }
}
</script>

<style scoped>

</style>
