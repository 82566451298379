<template>
  <el-dialog
    v-loading="loading"
    title="晒图配置"
    :visible.sync="dialogVisible"
    center
    width="600px"
    :before-close="handleClose">

    <el-form ref="postForm" label-width="120px" :model="postForm" :rules="rules">
      <el-form-item label="类型">
        <el-radio-group v-model="postForm.plan_type">
          <el-radio :label="1">文字</el-radio>
          <el-radio :label="2">图片</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="晒图人">
        <el-radio-group v-model="postForm.plan_uid">
          <el-radio :label="item.id" v-for="(item, idx) in accounts" :key="idx">{{ item.user_id }}</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="群组">
        <el-radio-group v-model="postForm.conversation_id">
          <el-radio :label="item.group_id" v-for="(item, idx) in groups" :key="idx">{{ item.groupInfo.name }}</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item v-if="postForm.plan_type === 1" prop="nickname" label="内容">
        <el-input v-model="postForm.content" type="textarea" />
      </el-form-item>
      <el-form-item v-if="postForm.plan_type === 2" prop="avatar" label="图片">
        <el-upload
          class="avatar-uploader"
          action="asdf"
          :auto-upload="true"
          :show-file-list="false"
          :before-upload="uploadPicture">
          <el-image :src="thumb(postForm.content)" style="width: 75px; height: 75px"/>
        </el-upload>
      </el-form-item>

      <el-form-item label="计划时间">
        <el-time-picker v-model="postForm.plan_time" type="time" return-type="string" />
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { blobToBase64, dateTimeFormat, thumb } from "@/utils/utils";
import { requestHttp } from "@/api/main";

export default {
  name: "DialogPostPlan",
  data() {
    return {
      dialogVisible: false,
      userId: 0,
      loading: false,
      accounts: [],
      groups: [],
      defaultForm: {
        id: 0,
        plan_uid: 0,
        plan_user_id: '',
        plan_type: 1,
        conversation_id: 0,
        content: '',
        plan_time: ''
      },
      postForm: {},
      rules: {}
    }
  },
  methods: {
    dateTimeFormat,
    thumb,
    uploadPicture(file) {
      const that = this
      const isLt2M = file.size / 1024 / 1024 < 20;

      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20 MB!');
        return false
      }

      blobToBase64(file, function (res) {
        that.postForm.content = res
      });

      return false;
    },
    toggle(uId) {
      this.postForm = JSON.parse(JSON.stringify(this.defaultForm))
      this.postForm.id = uId || 0
      this.fetchAccount()
      this.getAllGroup()
      if (this.postForm.id > 0) {
        this.fetchData()
      }
      this.dialogVisible = true
    },
    getAllGroup() {
      const that = this
      that.groups = []
      requestHttp('agent/get_all_group', {}).then((res) => {
        if (res.code != 200) {
          that.$message.error(res.message)
        } else {
          that.groups = res.data
          if (this.postForm.id == 0) {
            this.postForm.conversation_id = that.groups[0].group_id
          }
        }
      })
    },
    fetchAccount() {
      const that = this
      that.accounts = []
      requestHttp('agent/get_virtual_users', {virtual_type: 3}).then((res) => {
        that.accounts = res.data
        if (res.code != 200) {
          that.$message.error(res.message)
        }
      })
    },
    fetchData() {
      const that = this
      that.loading = true
      requestHttp('agent/get_post_plan_info', this.postForm).then((res) => {
        that.loading = false
        that.postForm = res.data
        if (that.postForm.plan_type == 1) {
          that.postForm.post_text = that.postForm.content
        } else {
          that.postForm.post_picture = that.postForm.content
        }
      }).catch(() => {
        that.loading = false
      })
    },
    handleSubmit() {
      const that = this
      requestHttp('agent/save_post_plan', this.postForm).then((res) => {
        console.log(res)
        if (res.code == 200) {
          that.$message.success(res.message)

          setTimeout(function () {
            that.handleClose()
          }, 2000)
        } else {
          that.$message.error(res.message)
        }
      })
    },
    handleClose() {
      this.dialogVisible = false
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
