<template>
  <el-dialog
    v-loading="loading"
    title="虚拟托号"
    :visible.sync="dialogVisible"
    center
    width="600px"
    :before-close="handleClose">

    <el-form ref="postForm" label-width="120px" :model="postForm" :rules="rules">
      <el-form-item prop="avatar" label="头像">
        <el-upload
          class="avatar-uploader"
          action="asdf"
          :auto-upload="true"
          :show-file-list="false"
          :before-upload="uploadAvatar">
          <el-image lazy :src="thumb(postForm.avatar)" style="width: 75px; height: 75px"/>
        </el-upload>
      </el-form-item>
      <el-form-item label="类型">
        <el-radio-group v-model="postForm.virtual_type">
          <el-radio :label="2">群杀托号</el-radio>
          <el-radio :label="3">晒图账号</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="postForm.id === 0" prop="user_id" label="用户名">
        <el-input v-model="postForm.user_id" type="text" />
      </el-form-item>
      <el-form-item prop="nickname" label="昵称">
        <el-input v-model="postForm.nickname" type="text" />
      </el-form-item>
      <el-form-item label="生日">
        <el-date-picker v-model="postForm.birthday" type="date" return-type="string" />
      </el-form-item>
      <el-form-item label="性别">
        <el-radio-group v-model="postForm.gender">
          <el-radio :label="0">不公开</el-radio>
          <el-radio :label="1">男</el-radio>
          <el-radio :label="2">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="个性签名">
        <el-input type="textarea" v-model="postForm.motto" placeholder="请输入个性签名" />
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { blobToBase64, dateTimeFormat, thumb } from "@/utils/utils";
import { requestHttp } from "@/api/main";

export default {
  name: "DialogSubAccount",
  data() {
    return {
      dialogVisible: false,
      userId: 0,
      loading: false,
      defaultForm: {
        id: 0,
        avatar: '',
        user_id: '',
        nickname: '',
        birthday: '',
        motto: '',
        gender: 0,
        mobile: '',
        email: '',
        add_friend_type: 3,
        virtual_type: 2
      },
      postForm: {},
      rules: {}
    }
  },
  methods: {
    dateTimeFormat,
    thumb,
    uploadAvatar(file) {
      const that = this
      const isLt2M = file.size / 1024 / 1024 < 20;

      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20 MB!');
        return false
      }

      blobToBase64(file, function (res) {
        that.postForm.avatar = res
      });

      return false;
    },
    toggle(uId) {
      this.postForm = JSON.parse(JSON.stringify(this.defaultForm))
      this.postForm.id = uId || 0
      if (this.postForm.id > 0) {
        this.fetchData()
      }
      this.dialogVisible = true
    },
    fetchData() {
      const that = this
      that.loading = true
      requestHttp('agent/get_virtual_user_info', this.postForm).then((res) => {
        console.log(res)
        that.loading = false
        that.postForm = res.data
      }).catch(() => {
        that.loading = false
      })
    },
    handleSubmit() {
      const that = this
      requestHttp('agent/save_virtual_user', this.postForm).then((res) => {
        console.log(res)
        if (res.code == 200) {
          that.$message.success(res.message)

          setTimeout(function () {
            that.handleClose()
          }, 2000)
        } else {
          that.$message.error(res.message)
        }
      })
    },
    handleClose() {
      this.dialogVisible = false
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
